import { IModal, IOffcanvas } from '@/components';
import { IInput, ISelect } from '@/components/form';
import router from '@/router';
import store from '@/store';
import { ITranslations } from '@/translations/index';

export const productsModal: IModal = {
  isActive: true,
  isCard: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/product/${store.getters.getContextExhibitor.id}/products`,
    );
  },
};

export const productsOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/product/${store.getters.getContextExhibitor.id}/products`,
    );
  },
};

interface IFilters {
  search: IInput;
  filter: ISelect;
}

export const filters = () => {
  const translations = store.getters.getTranslations as ITranslations;
  const filters: IFilters = {
    search: {
      id: 'filter-search',
      name: 'filter_search',
      value: '',
      placeholder: translations.common.search,
      type: 'text',
    },
    filter: {
      id: 'filters-filter',
      name: 'filters_filter',
      value: 'all',

      options: [
        {
          value: 'all',
          label: translations.common.all,
        },
        {
          value: 'favorites',
          label: translations.common.favorites,
        },

      ],
    },
  };
  return filters;
};
