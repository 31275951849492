import { MutationPayload } from 'vuex';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Loading from '@/components/loading.vue';
import { IProductParams } from '@/store/modules/products';
import store from '@/store';
import ProductCard from '@/partials/ProductCard.vue';
import { IProductItem } from '@/models/product';
import { getProductList } from '@/api/products';
import router from '@/router';
import { ICategory, ISubCategory } from '@/models/category';
import { getCategories, getSubCategories } from '@/api/categories';
import { filters } from './index';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Input,
    Loading,
    Select,
    ProductCard,
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      itemCount: 0,
      loading: false,
      filters: filters(),
      event: 0,
      searchTimeout: 0,
      searchString: '',
      products: [] as IProductItem[],
      categories: [] as ICategory[],
      subCategories: [] as ISubCategory[],
      selectedCategory: 0,
      selectedSubCategory: 0,
    }
  },

  setup() {
    const { contextEvent, translations } = useContext();
    return { contextEvent, translations }
  },

  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.selectedCategory = parseInt(router.currentRoute.query.category as string, 10);
    this.selectedSubCategory = parseInt(router.currentRoute.query.subcategory as string, 10);

    if (this.contextEvent.id) {
      this.initCategories();
    }

    const params: IProductParams = {
      page: 1,
      event: this.event,
      page_size: 18,
      subcategory: this.selectedSubCategory,
      category: this.selectedCategory ? this.selectedCategory : undefined,
      submitted: true,
    };

    if (router.currentRoute.query.search) {
      this.searchString = router.currentRoute.query.search as string;
      params.search = this.searchString;
    }

    this.loading = true;

    getProductList(params).then((response) => {
      this.products = response.data.results;
      this.pageCount = response.data.page_count;
      this.itemCount = response.data.item_count;
      this.loading = false;
    });
    this.initFilters();
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.initFilters();
        if (this.contextEvent.id) {
          this.initCategories();
        }
      }
    });
  },
  methods: {
    initCategories() {
      const categoriesParams = {
        event: this.event,
      };

      getCategories(categoriesParams).then((response) => {
        this.categories = response.data.results;
      });

      if (this.selectedCategory) {
        const subCategoriesParams = {
          event: this.event,
          category: this.selectedCategory,
        };
        getSubCategories(subCategoriesParams).then((response) => {
          this.subCategories = response.data.results;
        });
      }
    },

    initFilters() {
      this.filters = filters();
      this.filters.search.onInput = this.search;
      this.filters.filter.onChange = this.filter;
      this.filters.search = {
        ...this.filters.search,
        value: this.searchString
      };
    },

    nextPage() {
      this.currentPage += 1;
      this.loading = true;
      const params: IProductParams = {
        page: this.currentPage,
        event: this.contextEvent.id,
        page_size: 18,
        submitted: true,
      };

      if (this.searchString) {
        params.search = this.searchString;
      }

      getProductList(params).then((response) => {
        this.products = [...this.products, ...response.data.results];
        this.pageCount = response.data.page_count;
        this.itemCount = response.data.item_count;
        this.loading = false;
      });
    },

    categoryActive(category: string) {
      if (router.currentRoute.fullPath.includes(`category=${category}`)) {
        return true;
      }
      console.log(router.currentRoute.fullPath);

      return false;
    },

    search(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.loading = true;

        const params: IProductParams = {
          page: 1,
          event: this.contextEvent.id,
          page_size: 18,
          search: value as string,
          reset: true,
          submitted: true,
        };

        getProductList(params).then((response) => {
          this.products = response.data.results;
          this.pageCount = response.data.page_count;
          this.itemCount = response.data.item_count;
          this.loading = false;
        });

        this.searchString = value as string;
      }, 1000);
    },

    filter(value: string) {
      this.loading = true;

      const params: IProductParams = {
        page: 1,
        event: this.contextEvent.id,
        page_size: 18,
        reset: true,
        submitted: true,
      };

      if (value === 'favorites') {
        params.favorite = true;
      }

      getProductList(params).then((response) => {
        this.products = response.data.results;
        this.pageCount = response.data.page_count;
        this.itemCount = response.data.item_count;
        this.loading = false;
      });
    }
  },
  watch: {
    $route() {
      this.selectedCategory = parseInt(router.currentRoute.query.category as string, 10);
      this.selectedSubCategory = parseInt(router.currentRoute.query.subcategory as string, 10);

      const params: IProductParams = {
        page: 1,
        event: this.event,
        page_size: 20,
        subcategory: this.selectedSubCategory,
        category: this.selectedCategory ? this.selectedCategory : undefined,
        submitted: true,
      };
      this.loading = true;
      getProductList(params).then((response) => {
        this.products = response.data.results;
        this.pageCount = response.data.page_count;
        this.itemCount = response.data.item_count;
        this.loading = false;
      });
      this.initCategories();
    }
  }

})

